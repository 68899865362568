<template>
  <div>
    <c-table
      ref="table"
      title="소방 훈련·교육 참석자 현황 목록"
      :columns="grid.columns"
      :data="traningEdu.users"
      :gridHeight="grid.height"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable && !disabled"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="traningEdu.users"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduActionUser',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',
      }),
    },
    traningEdu: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduId: '',  // 소방 훈련·교육 계획/결과 일련번호
        plantCd: '',  // 사업장코드
        traninigEduDt: '',  // 훈련·교육 일시
        sopFireFightingTraningEduStepCd: '',  // 소방 훈련·교육 계획/결과 진행상태
        traninigEduName: '',  // 소방 훈련·교육 계획/결과명
        locationName: '',  // 장소
        sopFireFightingTraningEduTargetCd: '',  // 소방 훈련·교육 대상
        sopFireFightingTraningEduPracticeCd: '',  // 소방 훈련·교육 실습
        sopFireFightingTraningEduTheoryCd: '',  // 소방 훈련·교육 이론
        deptCd: '',  // 주관부서
        fireSafetyManagerId: '',  // 소방안전관리자
        fireSafetyManagerQualitClassCd: '',  // 소방안전관리자 자격구분
        fireSafetyManagerNote: '',  // 소방안전관리자 비고
        sopFireFightingTraningEduFormCd: '',  // 소방 훈련 형태
        participatingInstitutions: '',  // 참여기관
        sopFireFightingTraningScenarioId: '',  // 소방 훈련 시나리오 일련번호
        traningPlan: '',  // 훈련계획
        eduPlan: '',  // 교육계획
        evaluationPlanDt: '',  // 평가계획일시
        evaluationUserId: '',  // 평가자
        evaluationMethod: '',  // 평가방법
        evaluationDt: '',  // 평가일시
        educationInstructorName: '',  // 교육강사
        totalNumber: '',  // 총원
        attendNumber: '',  // 참여인원
        nonAttendNumber: '',  // 미참석
        selfTotalNumber: '',  // 자위소방대 총원
        selfLeaderId: '',  // 자위소방대 대장명
        selfLeaderPhoneNumber: '',  // 자위소방대 대장 연락처
        selfUnitLeaderNumber: '',  // 자위소방대 부대장 인원
        selfNotiContactNumber: '',  // 자위소방대 통보연락 인원
        selfInitFireExtingNumber: '',  // 자위소방대 초기소화 인원
        selfEvacuGuidNumber: '',  // 자위소방대 피난유도 인원
        selfEmergencyContactNumber: '',  // 자위소방대 비상연락 인원
        mainContent: '',  // 주요내용
        mainAchievements: '',  // 주요성과
        problem: '',  // 문제점
        supplementaryMatters: '',  // 보완사항
        actionTaken: '',  // 조치사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        users: [],
        scenaios: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLNAME',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: 'LBLPOSITION',
            align: 'center',
            sortable: true
          },
          {
            name: 'attendanceFlag',
            field: 'attendanceFlag',
            label: '참석여부',
            align: 'center',
            sortable: false,
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            setHeader: true,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingTraningEdu.user.list.url
      this.saveUrl = transactionConfig.fft.fireFightingTraningEdu.user.save.url
      // code setting
      let height = this.contentHeight - 150
      if (height < 650) {
        height = 650
      }
      this.grid.height = String(height) + 'px'
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingTraningEduId: this.popupParam.sopFireFightingTraningEduId
      };
      this.$http.request((_result) => {
        this.$set(this.traningEdu, 'users', _result.data)
      },);
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.traningEdu.users)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>

<style lang="sass">
.fire-fighting-traning-edu-year-gubun-tr
  td
    text-align: center
    color: white !important
    font-size: 1rem !important
    font-weight: 600
  .text-align-right
    text-align: right
</style>